<template>
  <div class="submitStages">
    <div class="go-back">
      <div @click="toInfo">
        <van-icon name="arrow-left" class="icon-left" />
        <span>信息确认</span>
      </div>
    </div>
    <div class="content">
      <div class="content-item">
        <div class="item-price">
          <div>分期金额</div>
          <div class="price">
            <span>{{ infoList.price }}</span
            >
          </div>
        </div>
        <div style="background: #F9F9F9;border-radius: 16px;padding: 4px 32px 10px 32px">
            <div class="item-hint">
                <div class="hint-table">产品</div>
                <div>{{ product_name }}</div>
            </div>
            <div class="item-hint">
                <div class="hint-table">付款方式</div>
                <div>{{ package_name }}</div>
            </div>
            <div class="item-hint">
                <div class="hint-table">分期期限</div>
                <div>{{ total_stage }}月</div>
            </div>
        </div>
      </div>
      <div class="hint">
        <div class="hint-title">您正在申请一笔分期业务</div>
        <div class="hint-content">
          您正在申请一笔分期，请确认您的信息无误，申请成功后需按期足额付款，否则将会影响您的信用
        </div>
      </div>
      <div class="consent">
        <van-checkbox icon-size="16px" v-model="consent"
          >同意

          <span class="contract" @click.stop="onAgreement"
            >《赊购分期付款协议》</span
          >
          <span class="contract" @click.stop="onServeContract"
            >《服务合同》</span
          >
          <span
            class="contract"
            v-if="businessInfo.is_has_contract"
            @click.stop="onContract"
            >《{{ businessInfo.contract_name }}》</span
          >
        </van-checkbox>
      </div>
      <div style="margin: 16px">
        <div v-if="loading">
          <div class="submit-button" style="background: #1677ff" v-if="consent">
            提交中...
          </div>
        </div>
        <div v-else>
          <div
            class="submit-button"
            v-if="consent"
            @click="onSubmit"
          >
            确认提交
          </div>
          <div class="submit-button" v-else>确认提交</div>
        </div>
      </div>
    </div>
    <van-action-sheet
      v-model="showContractSheet"
      :title="businessInfo.contract_name"
      style="height: 500px;"
    >
      <!-- <pdf
        ref="pdf"
        :src="pdfUrl"
        :page="pageNum"
        :rotate="pageRotate"
        @progress="loadedRatio = $event"
        @page-loaded="pageLoaded($event)"
        @num-pages="pageTotalNum = $event"
        @link-clicked="page = $event"
      >
      </pdf>
      <div class="pdf-function">
        <div @click.stop="prePage">上一页</div>
        <div>{{ pageNum }}/{{ pageTotalNum }}</div>
        <div @click.stop="nextPage">下一页</div>
      </div> -->
        <canvas id="canvas1" width="250" height="250" v-show="false"></canvas>
        <canvas id="canvas2" width="250" height="250" v-show="false"></canvas>
        <div id="pdfDom" class="html" v-html="htmlData" style="width: 100%;"/>
    </van-action-sheet>
    <van-action-sheet v-model="showAgreement" title="赊购分期付款协议">
      <div class="stage-content">
        <p>合同编号:【_】</p>
        <div style="margin: 10px 0px">
          <div class="div-line">甲方:___</div>
          <div class="div-line">乙方:___</div>
          <div class="div-line">身份证号:___</div>
        </div>
        <p class="stage-text">
          根据中华人民共和国民法典相关法律法规的规定，就甲方为乙方提供服务，乙方分次向甲方支付服务费的事宜，经双方友好协商，达成如下协议：
        </p>
        <div class="stage-title">一、服务内容</div>
        <div>
          <div>服务项目：________</div>
          <div>门店名称：________ 门店地址：________</div>
        </div>
        <div class="stage-title">二、费用</div>
        <div>
          <div>
            1、乙方因购买以上服务内容应向甲方支付服务费用，并申请对全部或部分服务费用分次支付。
          </div>
          <div>
            2、乙方认可并同意：甲方为一家专业提供专业服务的经营性企业，本协议第一条提及的服务由甲方向乙方提供，乙方申请的赊购付款相关服务（包括订单咨询、技术支持、账户维护管理等）由甲方合作的第三方通过运营的平台向乙方提供
          </div>
          <div>
            3、乙方申请赊购分期支付服务费用总金额_______元，按如下约定付款日及每期付款金额（单位：元）按期支付服务费用。乙方每期付款日及每月付款金额明细如下：
            <div style="display: flex; justify-content: center">
              <table border="1">
                <tr>
                  <th>期数</th>
                  <th>应付服务费</th>
                  <th>付款日期</th>
                </tr>
                <tr>
                  <td>第1期</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>...</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>第N期</td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </div>
            <div class="stage-text">
              合计赊购分期应付服务费总金额：_______元。期限为____个月，自____年____月____日到____年____月____日止（与实际赊购分期付款日期不一致的，以实际分期付款申请通过日期起算，具体以平台显示的为准，对此乙方知悉并同意）。如付款日为每月30日或31日，若遇到天数不足30天或31天的月份，付款日为应付款当月的最后一日。如果付款日遇到法定假日或公休日，付款日期不顺延。
            </div>
          </div>
          <div>
            4、乙方付款方式：
            <div>乙方的付款方式可采取以下任意一种：</div>
            <div>
              （1）乙方在本协议所约定的付款日当日11:00前，按当期应付费用总额将款项足额存入乙方在平台绑定的银行卡中，乙方授权甲方自行或通过合作的支付机构或银行在付款日划扣乙方当期的应付款项；若甲方将对乙方享有的债权转让给其他第三方的，乙方同意债权受让方有权在付款日自行或委托第三方通过支付机构或银行从乙方在平台绑定的银行卡中划扣乙方当期的应付款项。如届时乙方在平台绑定的银行卡中金额不足以支付当期应付费用的，则甲方或债权受让人有权拒绝自行或委托第三方通过支付机构或银行进行划扣，由此造成乙方逾期付款的，乙方自行承担逾期付款产生的所有责任。
            </div>
            <div>
              （2）乙方在本协议所约定的付款日当日24:00前，通过平台按期足额支付当期应付费用总额，如截至付款日24:00未完成支付，乙方构成逾期付款，乙方需承担逾期付款产生的所有责任。
            </div>
          </div>
        </div>
        <div class="stage-title">三、提前付款</div>
        <div>
          <div>
            1、本协议所约定的赊购分期付款期限内，乙方可申请提前清偿剩余的未付部分或所有款项。
          </div>
          <div>
            2、乙方申请提前付款的，应自行通过平台发起提前付款申请，申请通过后应在结清全部已到期款项后，于申请提前付款之日所在当期付款日前向甲方支付剩余乙方应付的部分或全部款项（包括当期应付的服务费、剩余分期付款期限所涉所有或部分服务费、已产生的逾期付款的违约金等应向甲方支付的款项），并向甲方支付因提前付款产生的支付手续费，该手续费金额按照实际申请提前清偿款项总额的2.5%计算。
          </div>
          <div>
            3、若乙方申请提前付款通过后，但未按照申请通过后确定金额支付款项，则乙方的提前付款申请自行取消，乙方应继续按照本协议的约定继续付款。
          </div>
        </div>
        <div class="stage-title">四、声明与承诺</div>
        <div>
          <div>
            1、甲方承诺按照国家和地方关于服务质量、消费者权益保护等方面的法律法规，诚实信用地为乙方提供优质的服务。
          </div>
          <div>
            2、乙方同意并授权甲方或债权受让人在本协议所约定的每月付款日，或在乙方逾期付款后任一时间点，自行或委托第三方通过支付机构或银行从乙方在平台绑定的银行卡或乙方名下的任一银行账户中划扣乙方到期应付服务费、账户管理费以及本协议所约定的手续费、违约金等。且乙方知悉前述划扣无需另行取得乙方的同意或通知乙方。
          </div>
          <div>
            3、本协议签订后，甲方依法享有收取乙方服务费的权利，乙方尚未支付的款项即为甲方享有的应收账款债权。乙方同意甲方有权转让该等应收账款债权。
          </div>
          <div>
            4、若甲方向第三方转让本协议项下甲方对乙方享有的应收账款债权的，乙方同意甲方或甲方授权的第三方通过乙方在平台预留的手机号或电子邮箱或通过平台站内信方式向乙方发送债权转让通知，债权转让通知一经发送即视为送达。
          </div>
          <div>
            5、乙方知晓：甲方转让该等应收账款后，受让方仍有权转让该等应收账款债权。
          </div>
        </div>
        <div class="stage-title">五、保密义务</div>
        <div>
          <div>
            1、为确保乙方身份真实性以及向乙方提供更好的保障，乙方须向甲方、平台提供身份证明、联系人信息、银行卡信息等信息（简称“个人信息”，以下同），且乙方授权平台将乙方个人信息提供给第三方机构为甲方提供实名认证服务。乙方同意并授权甲方、甲方合作的第三方平台，将乙方的信息用于客户服务、安全防范、诈骗监测、存档和备份等用途；乙方了解并同意，甲方、甲方合作的第三方，也可能通过使用或整合所收集的乙方信息，以及取得乙方授权或依据法律共享的信息，来综合判断乙方账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
          </div>
          <div>
            2、甲乙双方应对本协议内容及因本协议的签署和履行而获知的各项信息保密。
          </div>
          <div>
            3、甲乙双方对因本协议的履行而必须获知保密信息的雇员、代理或其他合作的第三方披露保密信息的，应确保该些人员同样遵守本协议约定之保密义务
            。
          </div>
        </div>
        <div class="stage-title">六、违约责任</div>
        <div>
          <div>
            1、乙方未按本协议约定完成付款义务的，每迟延一日应向甲方承担剩余服务费总额（含已到期及未到期）×0.05%的逾期违约金。且一旦乙方逾期付款，除需向甲方支付前述逾期违约金外，还应额外向甲方支付当期应付服务费金额×2%的当期逾期违约金，如乙方累计多期逾期付款的，则逾期违约金按期累计。
          </div>
          <div>
            2、如乙方迟延付款，甲方或债权受让人有权对乙方进行逾期付款清收（清收方式包括但不限于电话通知、上门通知、发催收函、诉讼、仲裁等），并有权委托其他主体包括但不限于律师事务所、第三方机构等（简称“其他机构”）进行上述借款的违约提醒及催收工作，若委托其他主体则甲方或债权受让人有权将乙方个人信息、联系方式、地址提供给其他机构。乙方延迟还款且经甲方或债权受让人提醒仍未还款超过3日的，甲方或债权受让方有权自主或通过第三方将乙方违约信用记录上传至平台、芝麻信用、民间征信机构或银行征信系统。
          </div>
          <div>
            3、如乙方出现以下一项或多项情况时，则视为乙方严重违约，甲方或债权受让人有权要求乙方立即提前清偿所有款项（包括已到期、未到期的服务费及违约金及提前清偿应支付的手续费（手续费=提前清偿应支付款项*2.5%）等）：
            <div>3.1 乙方累计逾期付款三期以上的，包含三期；</div>
            <div>3.2 乙方提供虚假信息或隐瞒重要事实的；</div>
            <div>
              3.3 乙方逃避、拒绝与甲方或债权受让人进行沟通或拒绝承认欠款事实；
            </div>
            <div>3.4 其他对甲方应收账款产生严重不利影响的事件或行为。</div>
          </div>
          <div>
            4、除本协议另有约定外，甲乙任何一方违反本协议，应赔偿因违约而给对方造成的全部损失，包括但不限于调查费、诉讼费、律师费等。
          </div>
        </div>
        <div class="stage-title">七、通知与送达</div>
        <div>
          <div>
            1、本协议项下双方之间的一切通知均可通过传真、邮递、快递、电子邮件、短信或双方同意的其他方式送达以下地址：
            <div>
              1.1 如发送给甲方：
              <div>联系人： 地址：</div>
              <div>电话： 电子邮箱：</div>
            </div>
            <div>
              1.2 如发送给乙方：
              <div>联系人： 地址：</div>
              <div>电话： 电子邮箱：</div>
            </div>
          </div>
          <div>
            2、本协议项下的通知、要求、本协议所涉之应收账款清收、诉讼（仲裁）（包括一审、二审、再审和执行程序）的法律文书或其他通信可按照上述约定的地址或联系方式交付或发送至接收方。
          </div>
          <div>
            3、就本协议项下一方给予另一方的任何通知、要求、应收账款催收函或其他通信，一方可通过本协议提及的联系方式或甲乙双方在平台预留的联系方式向对方发送，其中电话、电子邮件等一经发出即视为已送达接收方；邮政信函于投寄之日起第三日即视为已送达接收方；如派人专程送达，则接收方签收日视为送达，接收方拒收的，送达人可采取拍照、录像方式记录送达过程，并将文书留置，亦视为送达。
          </div>
          <div>
            4、如果任何一方提供的上述联系方式发生变更的，应在变更后三日内书面通知对方；在本协议项下应收账款进入诉讼或仲裁阶段后，则须以书面方式告知审理机关。否则按原联系方式发出的通知或其他文书，即使变更方没有收到，仍视为送达。
          </div>
        </div>
        <div class="stage-title">八、其他</div>
        <div>
          <div>
            1、甲、乙双方就本协议事项进行的任何其他口头或书面约定，与本协议不一致的以本协议为准。
          </div>
          <div>
            2、因本协议引起的或与本协议有关的一切争议，甲乙双方首先应当通过友好协商解决；未能协商解决的，交由甲方所在地或乙方所在地有管辖权的人民法院诉讼解决；如发生债权转让，任何一方均有权向债权受让人所在地人民法院起诉，多次债权转让的，任何一方均有权向债权最终受让人所在地人民法院起诉。
          </div>
          <div>
            3、如因乙方逾期还款或发生本合同约定的其他违约行为导致甲方或债权受让人提起诉讼，且诉讼标的额在5万元以内（含）（如诉讼管辖地区相关文件规定的适用小额诉讼程序标准低于该标准的，则以相关文件规定为准）的，双方同意依法优先适用小额诉讼程序审理。双方均确认在本协议签订前已对小额诉讼程序的审判组织、一审终审、审理方式、审理期限、举证期限、诉讼费用交纳标准等相关事项进行充分了解，明确知晓小额诉讼程序为一审终审；由审判员一人独任审理；人民法院可以通过简便方式传唤当事人、送达诉讼文书和审理案件；举证期限一般不超过七日；人民法院作出的管辖权异议裁定和驳回起诉裁定，一经作出即刻生效；适用小额诉讼程序的案件一般在立案之日起两个月内审结，在特殊情况下经审理法院院长批准可以延长一个月等。
          </div>
          <div>4、乙方同意，本协议自双方通过线上签署后生效。</div>
          <div>
            5、本协议中涉及乙方的义务和责任之条款均已经以醒目的方式对乙方予以提示，乙方亦明确各条款之事项并予以接受。
          </div>
        </div>
        <div>（以下无正文）</div>
        <div style="margin: 20px 0px" class="signature">
          <div class="first-party">
            <div class="div-line">甲方:_____</div>
            <div class="div-line">年 月 日</div>
          </div>
          <div class="second-party">
            <div class="div-line">乙方:_____</div>
            <div class="div-line">年 月 日</div>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="showContract" title="服务合同">
      <div class="stage-content">
        <p>合同编号:【_】</p>
        <div style="margin: 20px 0px">
          <div class="div-line">本合同由如下双方签订于北京市朝阳区。</div>
          <div class="div-line">甲方:--</div>
          <div class="div-line">身份证号:_-联系电话:_联系地址:</div>
          <div class="div-line">电子邮箱:_</div>
          <div class="div-line">乙方:蚂蚁融信（北京）科技有限公司</div>
          <div class="div-line">
            联系地址: 北京市朝阳区甘露园中里青年汇13号楼1-206
          </div>
        </div>
        <div style="margin: 20px 0px">
          <div class="div-line">电子邮箱:mayizhuxue@163com</div>
          <div class="div-line">本合同双方，是指本合同项下甲方和乙方。</div>
          <div class="div-line">
            甲方拟通过乙方运营的先学后付平台(具体名称以实际展示的为准，简称“平台”)向机构申请赊买服务，乙方通过平台为甲方提供信息咨询、技术支持、订单管理等相关服务，双方在平等自愿基础上，经友好协商，就乙方为甲方提供服务事宜达成本合同。
          </div>
          <div class="div-line">机构:为甲方提供服务/商品的机构。</div>
          <div class="stage-title">第一条甲方权利义务:</div>
          <div>
            <div>
              1、甲方承诺并确保出具或提供的所有文件资料、信息均真实、完整、准确、有效。
            </div>
            <div>
              2、甲方应按照本合同约定向乙方支付服务费:甲方应按照乙方要求，提供充分的协助和配合。
            </div>
            <div>
              3、甲方保证在使用乙方服务前已对甲方向机构购买的服务/商品做了充分的了解，且已与机构就购买事宜达成合意。
            </div>
            <div>
              4、甲方承诺甲方对机构提供的服务/商品存在异议或甲方与机构存在争议，甲方自行与机构处理，与乙方无关。
            </div>
            <div>
              5、甲方授权乙方自行或通过第三方在甲方付款日(付款日以平台展示的为准)易宝支付第三方支付平台发送扣款指令，由第三方支付平台自行或通过第三方从甲方在平台绑定的银行账户或甲方的其他账户中划扣甲方当期应付款项至乙方。如果划扣失败，无论何种原因所致，甲方的付款义务不得因此减免，甲方应继续清偿未还款项。
            </div>
            <div>
              6、当甲方在平台绑定的银行账户发生变化时，主动办理账号变更手续，若不办理账号变更手续，按原账号划扣当期应付款项的，因此产生的后果由甲方承担。当甲方名下账户处于挂失、冻结等非正常状态而引起的扣款不成功时，产生的后果由甲方承担。
            </div>
            <div>
              7、甲方保证在还款日当天11:00前在平台绑定的账户中存入足额应付金额，否则乙方有权拒绝向第三方支付机构发送扣款指令，由此产生的逾期付款等后果由甲方自行承担。
            </div>
          </div>
          <div class="stage-title">第二条乙方权利义务:</div>
          <div>
            <div>
              1、 乙方应为甲方提供信息咨询、技术支持、订单管理等相关服务。
            </div>
            <div>
              2、
              乙方有权通过电话、短信、站内信等方式向甲方发送付款提醒。甲方延期付款的，乙方有权自行或通过第三方通过上门、电话、短信、发律师函、起诉、仲裁等方式对甲方进行清收。
            </div>
            <div>
              3、甲方延期付款的，甲方授权乙方自行或通过第三方将甲方的违约信息上报给中国人民银行征信系统或其他第三方征信、信用系统。
            </div>
          </div>
          <div class="stage-title">第三条 服务费条款:</div>
          <div>
            <div>
              1、 甲方按以下时间、金额向乙方支付服务费:
              <div style="display: flex">
                <div style="flex: 1; text-align: center">序号 【】</div>
                <div style="flex: 1; text-align: center">付款日【】</div>
                <div style="flex: 1; text-align: center">服务费金额 【】</div>
              </div>
              <div>
                若上述支付时间、金额与平台展示的不一致，则以平台展示的为准。
              </div>
            </div>
            <div>
              2、若在本协议有效期内发生甲方与机构解除购买关系的，甲方已支付服务费不予退还，且解除所在月，甲方仍应向乙方按时足额支付当月对应的服务费。对此甲方知悉并同意。
            </div>
            <div>
              3、本协议有效期:自【】年【】月】日起至 【年【】月【】日止。
            </div>
          </div>
          <div class="stage-title">第四条 通知及送达条款:</div>
          <div>
            <div>
              1、
              对于因本合同引起的任何纠纷，甲方同意乙方。司法机关(如人民法院、仲裁机构等)可以手机短信、手机邮箱、电子邮件、支付宝、钉钉、微信、平台公告等任-一种方式向其送达法律文书。甲方指定接收法律文书的手机号码、电子邮箱或即时通讯工具账号为本合同中所载的电话号码、邮箱或账号，如果没有载明，则任何甲方在平台留存的号码、邮箱或账号均可进行送达。司法机关、乙方向前述号码、邮箱或账号等发出法律文书即视为送达。甲方同意司法机关、乙方可采取一种或多种方式向其送达法律文书司法机关、乙方采取多种方式向其送达法律文书的送达时间以上述送达方式中最先送达的为准。
            </div>
            <div>
              2、甲方确认的上述送达方式适用干各个司法阶段。如一审、二审、再审、执行以及督促程序(含支付令送达)等。若甲方上述送达地址有变更，甲方应当及时告知变更后的送达地址。如未予以告知，则原送达地址仍然有效。在司法程序中，如法院或仲裁委最终判决甲方承担责任的情况下，因此产生的所有费用(如律师费、支付令申请费和诉讼费等)均由甲方承担。
            </div>
            <div>
              3、
              甲方保证上述送达地址是准确、有效的;如果提供的送达地址不确切，或不及时告知变更后的地址，使法律文书无法送达或未及时送达，则甲方自行承担视为送达产生的法律后果。法院、仲裁机关、行政机关处理案件需要通知甲方的，适用上述通知条款。
            </div>
            <div>
              4、方同意付项支付还完毕前，如甲方留存手机号不能正常使用，包括但不限干停机、空号等，则乙方、债权受让人或其合作的第三方可以通过合法方式实现对甲方联系方式的虚拟修复。
            </div>
            <div>5、甲方同意通过网上/线上开庭的方式参与司法程序。</div>
          </div>
          <div class="stage-title">第五条争议与管辖:</div>
          <div>
            本合同项下及与本合同有关的纠纷，可向乙方所在地或合同签订地或债权受让人所在地人民法院起诉。
          </div>
          <div class="stage-title">
            第六条成立和生效本合同经双方通过线上签署后生效。
          </div>
          <div>
            甲方已全面阅读、理解本合同内容，并且完全理解和愿意接受。(以下无正文)
          </div>
        </div>
        <div style="margin: 20px 0px" class="signature">
          <div class="first-party">
            <div class="div-line">甲方:</div>
            <div class="div-line">（签字）</div>
            <div class="div-line">年 月 日</div>
          </div>
          <div class="second-party">
            <div class="div-line">乙方:蚂蚁融信（北京）科技有限公司</div>
            <div class="div-line">(签字/ 盖章)</div>
            <div class="div-line">年 月 日</div>
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { Toast } from "vant";
import { applyBill } from "@/api/bill.js";
import { selectProdcut, selectPackage } from "@/api/selectInfo.js";
import { stagesPreview } from "@/api/bill.js";
// import pdf from "vue-pdf";
import { contractInfo } from '@/api/util.js'
import { createSealWithNameAndTime, createNameWithTime } from '@/utils/canvas_help.js'
export default {
  name: "submitStages",
  props: [
    "tmp_save_id",
    "prestore_form",
    "fromocr",
    "productInfo",
    "business_id",
    "businessInfo",
    "infoUser"
  ],
  // components: {
  //   pdf,
  // },
  data() {
    return {
      loading: false,
      consent: false,
      showAgreement: false,
      showContract: false,
      infoList: {},
      product_name: "",
      package_name: "",
      total_stage: "",
      productList: [],
      packageList: [],
      contractText: "合同",
      contractShow: false,
      showContractSheet: false,
      pdfUrl: "",
      pageNum: 1,
      pageTotalNum: 1,
      pageRotate: 0,
      // 加载进度
      loadedRatio: 0,
      curPageNum: 0,
      htmlData: ''
    };
  },
  mounted() {
    if (this.fromocr) {
      this.initData();
    } else {
      this.submitApplication();
    }
    console.log(this.businessInfo)
    
  },
  methods: {
    async getHtml(url) {
      var res = await contractInfo({
        url: url
      })
      this.billInfo(res)
    },
    billInfo(res) {
      this.getInfo(res)
    },
    getInfo(res) {
      this.dealHtml(res)
    },
    dealHtml(res) {
            var content = res
            createSealWithNameAndTime('canvas1', this.businessInfo.contract_layer_name, this.businessInfo.contract_layer_name, '2023-11-12')
            createNameWithTime('canvas2', this.infoUser.real_name, '2023-11-12')
            // var html = ''
            if (content.indexOf("α") != -1) {
                content = content.replace("α", this.businessInfo.contract_layer_name)
            }
            if (content.indexOf("β") != -1) {
                content = content.replace("β", this.infoUser.real_name)
            }
            if (content.indexOf("γ") != -1) {
                content = content.replace("γ", this.infoUser.id_card_no)
            }
            if (content.indexOf("δ") != -1) {
                var product
                this.productList.map(p=> {
                  if (p.product_id == this.prestore_form.productId) {
                    product = p
                  }
                })
                if (product) {
                  content = content.replace("δ", product.product_name)
                }
            }
            if (content.indexOf("ε") != -1) {
                content = content.replace("ε", this.prestore_form.price)
            }
            if (content.indexOf("ε") != -1) {
                content = content.replace("ε", this.prestore_form.price)
            }
            if (content.indexOf("η") != -1) {
                let url = document.getElementById("canvas1").toDataURL("image/png")
                let img = `<image style='width=200px;height=200px;padding-top=250px' src='${url}'></image>`
                content = content.replace("η", img)
            }
            if (content.indexOf("θ") != -1) {
                let url = document.getElementById("canvas2").toDataURL("image/png")
                let img = `<image style='width=150px;height=150px;padding-top=250px' src='${url}'></image>`
                content = content.replace("θ", img)
            }
            this.htmlData = content
    },
    
    async submitApplication() {
      if (this.$route.query.ecode == 200 || this.prestore_form.code == 0) {
        this.initData();
      } else {
        this.$emit("nextStep", "bindingCard");
        Toast(this.$route.query.emsg || "未知错误");
      }
    },
    async initData() {
      var data = this.productInfo;
      var that = this;
      await this.getSelectProdcut(this.business_id);
      await this.getSelectPackage(this.business_id);
      that.infoList = data;
      var productObj = that.productList.find((obj) => {
        return obj.product_id == data.productId;
      });
      that.product_name = productObj.product_name;

      var packageObj = that.packageList.find((obj) => {
        return obj.package_id == data.packageId;
      });
      that.package_name = packageObj.repay_type == 1 ? "等额本金" : "组合还款";

      const stagesParme = {
        price: data.price,
        productId: data.productId,
        packageId: data.packageId,
      };
      await this.stagesPreview(stagesParme);
    },
    async onSubmit() {
      var that = this;
      that.loading = true;
      const parmes = {
        tmp_save_id: this.tmp_save_id,
      };
      console.log(parmes);
      const { code, data } = await applyBill(parmes);
      that.loading = false;
      if (code == 200) {
        console.log(data);
        that.loading = false;
        Toast("提交成功");
        that.$emit("nextStep", "pendingTrial");
      } else {
        that.loading = false;
      }
    },
    async stagesPreview(parme) {
      const { code, data } = await stagesPreview(parme);
      if (code == 200) {
        this.total_stage = data.total_stage;
      }
    },
    async getSelectProdcut(business_id) {
      const parme = {
        business_id: business_id,
      };
      const { code, data } = await selectProdcut(parme);
      if (code == 200) {
        this.productList = data;
      }
    },
    async getSelectPackage(business_id) {
      const parme = {
        business_id: business_id,
      };
      const { code, data } = await selectPackage(parme);
      if (code == 200) {
        this.packageList = data;
      }
    },
    onContract() {
      this.showContractSheet = true;
      if (this.businessInfo.contract_image && this.htmlData == '') {
        this.getHtml(this.businessInfo.contract_image)
      }
      // this.pdfUrl = this.businessInfo.contract_image;
    },
    prePage() {
      var p = this.pageNum;
      p = p > 1 ? p - 1 : this.pageTotalNum;
      this.pageNum = p;
    },
    nextPage() {
      var p = this.pageNum;
      p = p < this.pageTotalNum ? p + 1 : 1;
      this.pageNum = p;
    },
    pageLoaded(e) {
      this.curPageNum = e;
    },
    onAgreement() {
      this.showAgreement = true;
    },
    onServeContract() {
      this.showContract = true;
    },
    toInfo() {
      this.$emit("nextStep", "bindingCard");
    },
  },
};
</script>

<style lang="scss" scoped>
.submitStages {
  height: 100%;
  width: 100%;
  position: fixed;
  background: #f5f5f5;
  .go-back {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 30px;
    width: 100%;
    color: #fff;
    background: #FFB77F;
    height: 100px;
  }
  .content {
    padding: 30px;
    height: 100%;
    overflow-y: scroll;
    background: linear-gradient(360deg, #FFE5CC 0%, #FFB77F 100%);
    .content-item {
      background: #fff;
      box-shadow: 0px 0px 10px 0px #f5f5f5;
      border-radius: 20px;
      padding: 15px 30px;
      font-size: 30px;
      color: #333;
      .item-price {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0B0601;
        line-height: 40px;
        .price {
          font-size: 88px;
          font-family: Roboto-Black, Roboto;
          font-weight: 900;
          color: #0B0601;
          line-height: 103px;

        }
      }
      .item-hint {
        display: flex;
        border-bottom: 1px solid rgba(151, 151, 151, 0.16);
        padding: 32px 0;
        .hint-table {
          flex: 1;
        }
        .span {
          color: #0B0601;
          font-size: 30px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
      .item-hint:last-child {
        border: 0;
      }
    }
    .hint {
      padding: 45px;
      .hint-title {
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #552C00;
        line-height: 46px;
      }
      .hint-content {
        margin-top: 8px;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #552C00;
        line-height: 36px;
      }
    }
    .consent {
      display: flex;
      align-items: center;
      justify-content: center;
      .contract {
        color: #E89E50;
      }
    }
    .submit-button {
      width: 100%;
      height: 90px;
      background: #FFB77F;
      border-radius: 45px;
      font-size: 36px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .pdf-function {
    display: flex;
    div {
      flex: 1;
      text-align: center;
      color: #333;
      font-size: 32px;
      padding: 20px 0px;
    }
  }
  .stage-content {
    padding: 30px;
    table {
      border-collapse: collapse;
      border-spacing: 0;
      border-left: 1px solid #888;
      border-top: 1px solid #888;
    }
    th,
    td {
      border-right: 1px solid #888;
      border-bottom: 1px solid #888;
      padding: 5px 15px;
    }

    th {
      font-weight: bold;
    }
    .stage-text {
      text-indent: 2em;
    }
    .signature {
      display: flex;
      .first-party {
        width: 30%;
      }
      .second-party {
        flex: 1;
      }
    }
    .div-line {
      line-height: 2;
    }
    .stage-title {
      margin: 10px 0px;
    }
  }
}
</style>